import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×8\\@70% 1RM`}</p>
    <p>{`Strict Pullups 4×8`}</p>
    <p>{`then,`}</p>
    <p>{`200M Run`}</p>
    <p>{`30-S2OH (115/75)(RX+ 135/95)`}</p>
    <p>{`10-T2B`}</p>
    <p>{`400M Run`}</p>
    <p>{`20-S2OH`}</p>
    <p>{`20-T2B`}</p>
    <p>{`800M Run`}</p>
    <p>{`10-S2OH`}</p>
    <p>{`30-T2B`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      